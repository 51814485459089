<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-format-list-text</v-icon>
        Faaliyet Raporu
      </v-card-title>
      <v-card-subtitle>
        {{ $t("helper_text.reports.operating") }}
        <p>
          Faaliyetleri düzenlemek için
          <router-link :to="{ name: 'reports.cluster.operating-items' }"
            >buraya tıklayın</router-link
          >.
        </p>
      </v-card-subtitle>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit('pdf')" ref="form">
          <v-row dense>
            <v-col sm="3">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-select
                label="Faaliyet Türü"
                :items="careTypeList"
                v-model="formData.care_type_ids"
                multiple
              />
            </v-col>

            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-2"
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    İndir
                    <v-icon>mdi-menu-down-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleDownloadClick('odt')">
                    <v-list-item-title>
                      <v-icon>mdi-file-document-outline</v-icon> ODT
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('docx')">
                    <v-list-item-title>
                      <v-icon>mdi-file-document-outline</v-icon> DOCX
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('pdf')">
                    <v-list-item-title>
                      <v-icon>mdi-text-box-outline</v-icon> PDF
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm } from "@/view/mixins";
import { slugify } from "@/core/helpers";

export default {
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["careTypeList", "cluster", "clusterId"]),
  },
  watch: {
    clusterId() {
      this.iframeUrl = null;
    },
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
    );
    const max = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
    );

    return {
      iframeUrl: null,
      reportData: null,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
        care_type_ids: [],
      },
    };
  },
  methods: {
    handleFormSubmit(format) {
      format = format || "pdf";
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      // const params = Object.assign(this.buildSearchParams(this.formData));
      const params = {
        cluster_id: this.clusterId,
        item_date: {
          min: this.formData.date.min,
          max: this.formData.date.max,
        },
        order_by: "item_date",
        order: "asc",
        per_page: 2000,
      };

      if (this.formData.care_type_ids.length) {
        params.care_type_ids = this.formData.care_type_ids;
      }

      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query(`reports/operating-report-items`, { params })
        .then((response) => {
          // eslint-disable-next-line
          this.reportData = {};
          let i = 1;
          this.reportData.list = response.data.data.map((row) => {
            if (row.amount) {
              row.amount = this.numberToLocaleFormat(row.amount) + "₺";
            }

            if (!row.care_type_name) {
              row.care_type_name = "Genel";
            }

            if (row.item_date !== row.started_on) {
              row.item_date =
                new Date(row.started_on).toLocaleDateString() +
                "~" +
                new Date(row.item_date).toLocaleDateString();
            } else {
              row.item_date = new Date(row.item_date).toLocaleDateString();
            }
            row.index = i;

            i++;
            return row;
          });

          this.reportData.cluster = this.cluster.name;
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.reportData[index] = response.data.data[index];
            }
          }

          this.reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          this.reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();
          // this.reportData.totals = {
          //   expense_total:
          //     response.data.meta.totals.expense_total.toLocaleString(
          //       undefined,
          //       {
          //         minimumFractionDigits: 2,
          //         maximumFractionDigits: 2,
          //       }
          //     ),
          //   income_total: response.data.meta.totals.income_total.toLocaleString(
          //     undefined,
          //     {
          //       minimumFractionDigits: 2,
          //       maximumFractionDigits: 2,
          //     }
          //   ),
          // };

          // for (const index in this.reportData.incomes) {
          //   const item = this.reportData.incomes[index];
          //   item.amount = item.amount.toLocaleString(undefined, {
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2,
          //   });

          //   item.date = new Date(item.date).toLocaleDateString();
          //   item.index = parseInt(index) + 1;
          // }

          // for (const index in this.reportData.expenses) {
          //   const item = this.reportData.expenses[index];
          //   item.amount = item.amount.toLocaleString(undefined, {
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2,
          //   });

          //   item.date = new Date(item.date).toLocaleDateString();
          //   item.index = parseInt(index) + 1;
          // }

          return this.reportData;
        })
        .then((reportData) => {
          let fileName = this.cluster.name + "-";
          fileName += new Date(this.formData.date.min).toLocaleDateString();
          fileName += "-";
          fileName += new Date(this.formData.date.max).toLocaleDateString();
          fileName += "-FaaliyetRaporu";

          if (fileName.length >= 100) {
            fileName = fileName.substring(0, 99);
          }

          const params = {
            file_type: format,
            print_template: "Operating",
            receiptData: reportData,
            file_name: slugify(fileName),
          };

          return this.$api.post("print-file", params).then((response) => {
            if (format !== "pdf") {
              const anchor = document.createElement("a");
              anchor.href = response.data.data.url;
              anchor.target = "_blank";
              anchor.click();
            } else {
              this.iframeUrl = response.data.data.url;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadClick(format) {
      this.handleFormSubmit(format || "pdf", true);
    },
  },
};
</script>
